import { FC } from 'react';
import Head from 'next/head';

import DialoguePresenter from 'blocks/DialoguePresenter/DialoguePresenter';

//Components
import Header from 'blocks/Header/Header';
import Footer from 'blocks/Footer/Footer';

//Providers
import { SearchBarProvider } from 'blocks/search-bar/context';

import { IGlobalLayout } from 'types/interfaces';
import { formatCanonicalUrl } from 'utils/header';

const GlobalLayout: FC<IGlobalLayout> = ({
  children,
  memberships,
  currencies,
  countryCodes,
  metaData,
}) => {
  return (
    <>
      <Head>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
        <meta property="og:title" content={metaData.title} />
        <meta property="og:description" content={metaData.description} />
        <meta property="og:site_name" content="MyLE" />
        <meta property="og:url" content={metaData.url} />
        {metaData.type && <meta property="og:type" content={metaData.type} />}
        {metaData.image && (
          <meta property="og:image" content={metaData.image} />
        )}
        {metaData.author && <meta name="author" content={metaData.author} />}
        {metaData.publishedTime && (
          <meta
            property="article:published_time"
            content={metaData.publishedTime}
          />
        )}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={metaData.title} />
        <meta name="twitter:description" content={metaData.description} />
        <link rel="canonical" href={formatCanonicalUrl(metaData.url)} />
      </Head>
      <Header />
      <div className="main-content-container">{children}</div>
      <Footer />
      <DialoguePresenter
        memberships={memberships}
        currencies={currencies}
        countryCodes={countryCodes}
      />
    </>
  );
};

export default GlobalLayout;
