import { Heading } from 'components/text';
import { navigationPaths } from 'data/constants';
import styles from './LinkList.module.scss';
import { useRouter } from 'next/router';
import Link from 'next/link';

interface LinkItem {
  href: string;
  text: string;
}

/**
 * Predefined list of navigation links for the 404 page.
 * These links provide alternative navigation options when a user lands on a 404 page.
 */
const links: LinkItem[] = [
  { href: navigationPaths.OUR_HOTELS, text: 'Hotels' },
  { href: navigationPaths.MEMBERSHIP, text: 'Membership' },
  { href: navigationPaths.E_GIFT_CARD, text: 'Gift cards' },
  { href: navigationPaths.FAQ, text: "FAQ's" },
];

const LinkList = () => {
  const router = useRouter();

  /**
   * Handles click events on navigation links
   *
   * This function prevents the default link behavior and uses router.replace
   * instead of the default Link navigation to handle the browser back button:
   * When a user clicks one of these links and then uses the browser's back button,
   * router.replace ensures they return to their previous valid page instead of
   * triggering data fetching for the non-existent 404 route.
   *
   * @param href - The destination URL
   * @param e - The click event object
   */
  const handleClick = (href: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    router.replace(href);
  };

  return (
    <div className={styles.links}>
      {links.map(item => (
        <Link
          key={item.href}
          href={item.href}
          passHref
          className={styles.linkWrapper}
        >
          <a
            className={styles.link}
            onClick={handleClick(item.href)}
            role="link"
            tabIndex={0}
          >
            <Heading as="h5" weight="regular">
              {item.text}
            </Heading>
          </a>
        </Link>
      ))}
    </div>
  );
};

export default LinkList;
