import styles from './NotFound.module.scss';

import Container from 'components/container';
import { Heading } from 'components/text';
import Image from 'next/image';
import NotFoundImage from 'assets/not-found/not-found-img.jpg';
import LinkList from './link-list';
import { breakpoints } from 'data/constants';
import useMediaQuery from '@mui/material/useMediaQuery';

const NotFound: React.FC = () => {
  const isTabletOrMobile = useMediaQuery(`(max-width:${breakpoints.md})`);

  return (
    <Container className={styles.container}>
      <div className={styles.imageWrapper}>
        <Image
          className={styles.hero}
          priority
          src={NotFoundImage}
          alt="Not Found 404"
          quality={100}
          layout={isTabletOrMobile ? 'fill' : 'responsive'}
          objectPosition="center"
          objectFit="cover"
        />
      </div>
      <div className={styles.textOverlay}>
        <Heading as="h4" weight="demiBold" className={styles.title}>
          That’s embarrassing...
        </Heading>
        <Heading as="h5" weight="demiBold" className={styles.subtitle}>
          We can't seem to find the page you're looking for, here are some
          helpful links instead:
        </Heading>
        <LinkList />
      </div>
    </Container>
  );
};

export default NotFound;
