import type { NextPage, GetStaticProps } from 'next';

import PageService from 'services/PageService/PageService';
import { IGenericPage } from 'types/interfaces';
import GlobalLayout from 'blocks/Layouts/GlobalLayout';

import NotFound from 'blocks/not-found';
import { navigationPaths } from 'data/constants';

const Custom404: NextPage<IGenericPage> = ({ globalLayoutProps }) => {
  return (
    <GlobalLayout {...globalLayoutProps}>
      <NotFound />
    </GlobalLayout>
  );
};

export default Custom404;

export const getStaticProps: GetStaticProps = async () => {
  const genericPageProps = await PageService.getGenericPageProps({
    navPath: navigationPaths.NOT_FOUND,
  });
  return {
    props: {
      ...genericPageProps,
    },
  };
};
